@tailwind base;
@tailwind components;
@tailwind utilities;

.designCard {
  box-shadow: 0px 1px 2px #1018280d;
}
.designCard2 {
  box-shadow: 0px 0px 20px #0b0f180d;
}
.gradient-text {
  background: linear-gradient(90deg, #423125 0%, #525252 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.selectBg {
  background-image: url("./AdminPortal/images/dashboard/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 96%;
  background-size: 10px;
}
.mobileNavToggle {
  visibility: inherit !important;
}
