@font-face {
  font-family: garetExtraBold;
  src: url(../src/assets/fonts/garet/GaretExtraBold.ttf);
}
@font-face {
  font-family: GaretRegular;
  src: url(../src/assets/fonts/garet/GaretRegular.ttf);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Garet", sans-serif;
}
#main-sec {
  padding-inline: 180px;
}
button {
  padding: 15px 30px;
}
