.main-generator-sec {
    padding: 40px 180px;
    background-color: #F3F2F2;
}
.main-generator-box {
    padding: 70px 100px;
    background-color: #FFFFFF;
    border-radius: 25px;
}
.generator-box, 
.generator-form-intruction {
    padding: 80px 0px;
}
.main-form-head h1 {
    font-family: garet-extrablod;
    font-size: 60px;
    font-weight: 800;
    line-height: 72px;
    letter-spacing: 0em;

}
.main-form-head p {
    font-family: garet-regular;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

}
.form-input-box select {
    background-color: #F3F2F2;
    padding: 15px;
    border-radius: 10px;
}
.generator-box option {
    font-family: garet-extrablod;
    font-weight: 800;
    font-size: 18px;
    line-height: 45px;
}
.form-img-box {
    background-color: #F3F2F2;
    border-radius: 10px;
}
.form-img-box label {
    display: block;
    color: #4D4D4D;
    padding: 2.5rem;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
}
.form-img-box .upload-box {
    color: #988171;
}
.form-img-box .img-type {
    font-family: garet-regular;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
}
.generator-form-intruction {
    margin-left: 8rem;
}
.generator-form-intruction h3 {
    font-family: garet-extrablod;
    font-weight: 800;
    font-size: 25px;
    line-height: 45px;
}
.generator-form-intruction p {
    font-family: garet-light;
    font-weight: 300;
    font-size: 18px;
    line-height: 35px;
    color: #4D4D4D;
}
a.btn-design-space {
    font-family: garet-extrablod;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    background-color: #988171;
    padding: 15px 30px;
    border-radius: 10px 10px 10px 15px;
}

/* Responsive for mobile */
@media only screen and (max-width: 600px) {
    .main-generator-sec {
        padding: 40px;
    }
    .main-generator-box {
        padding: 20px;
    }
    .main-form-head h1 {
        font-size: 35px;
        line-height: 50px;
    }
    .generator-form-intruction {
        margin-left: unset;
    }
    .generator-form-intruction {
        padding: 20px 0px;
    }
    .form-img-box {
        margin-top: 15px;
    }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
    .main-generator-sec {
        padding: 40px;
    }
    .main-generator-box {
        padding: 20px;
    }
    .main-form-head h1 {
        font-size: 35px;
        line-height: 50px;
    }
    .generator-form-intruction {
        margin-left: unset;
    }
    .generator-form-intruction {
        padding: 20px 0px;
    }
}

/* Responsive For Tablet */
@media screen and (max-width: 992px) and (min-width: 768px) {
    .main-generator-sec {
        padding: 40px;
    }
    .main-generator-box {
        padding: 20px;
    }
    .main-form-head h1 {
        font-size: 35px;
        line-height: 50px;
    
    }
    .generator-form-intruction {
        margin-left: unset;
    }
    .generator-form-intruction {
        padding: 20px 0px;
    }
}

/* Responsive for Laptop */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .main-generator-sec {
        padding: 40px;
    }
}
@media only screen and (min-width: 993px) and (max-width: 1600px) {
    .main-generator-sec {
        padding: 40px;
    }
}