.main-header-sec {
  padding-top: 31px;
  padding-bottom: 31px;
  background-color: #ffffff;
  padding-inline: 180px;
  color: black;
}
img.header-logo {
  width: 257px;
}
.main-header-sec .navbar-nav li a {
  font-family: garet-light;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #121212;
}
.main-header-sec .login-sec a {
  font-family: garet-extrablod;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  padding: 15px 30px;
  border-radius: 10px 10px 10px 15px;
}
.btn-sign-in {
  background-color: #988171;
  color: black;
}
a.btn-sign-in:hover {
  color: #ffffff;
}
.btn-sign-up {
  color: #988171;
}
a.btn-sign-up:hover {
  color: #988171;
}

/* Mobile Respomsive */
@media only screen and (max-width: 667px) {
  .main-header-sec {
    padding-inline: 20px;
  }
  img.header-logo {
    width: 185px;
  }
}
/* Responsive For Tablet */
@media screen and (max-width: 992px) and (min-width: 668px) {
  .main-header-sec {
    padding-inline: 20px;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .main-header-sec .navbar-nav li a {
    font-size: 15px;
  }
  .main-header-sec .login-sec a {
    font-size: 15px;
    padding: 15px 20px;
  }
}

/* Responsive For laptop */
@media only screen and (min-width: 993px) and (max-width: 1600px) {
  .main-header-sec {
    padding-inline: 20px;
  }
}
