.main-help-sec {
    padding: 25rem 180px;
    position: relative;
    background-color: #988171;
}
.help-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 50px;
    background-color: #FFFFFF;
    border-radius: 25px;
}
.help-box .btn-submit {
    font-family: garet-extrablod;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    padding: 15px 30px;
    background-color: #988171;
    border-radius: 10px 10px 10px 15px;
}
/* Responsive For Mobile */
@media only screen and (max-width: 767px) {
    .help-box {
        width: 360px;
    }
}