/* Hero Section Start */
.main-hero-sec {
  padding-inline: 180px;
  background-color: #f3f2f2;
  height: 815px;
}
.hero-container {
  padding-top: 65px;
}

.hero-container h1 {
  font-family: blackmango-extrablod;
  font-weight: 900;
  font-size: 72px;
  line-height: 80px;
  color: #121212;
}
.hero-container p {
  font-family: garet-light;
  font-weight: 300;
  font-size: 24px;
  line-height: 35px;
  color: #4d4d4d;
}
.hero-container .btn-get-started {
  font-family: garet-extrablod;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  background-color: #988171;
  padding: 15px 30px;
  border-radius: 10px 10px 10px 15px;
}
/* Hero Section End */

/* Dashboard Section Start */
.main-dashboard-sec {
  padding-inline: 180px;
  background-color: #988171;
}
.dashboard-container {
  padding-bottom: 80px;
}
.dashboard-container .dashboard-img {
  margin-top: -29%;
  border-radius: 25px;
  box-shadow: 3px 3px 30px #5f5f5f;
}
.dashboard-container p {
  font-family: garet-extrablod;
  font-weight: 800;
  font-size: 45px;
  line-height: 65px;
  padding-top: 160px;
}
/* Dashboard Section End */

/* Atomic Section Start */
.main-atomic-sec {
  padding: 80px 180px;
  background-color: #e1e1e1;
}
.atomic-row-content {
  padding: 80px 158px;
  border-radius: 25px;
  margin-top: 100px;
  margin-bottom: 80px;
}
.left-atomic-text {
  margin-top: -175px;
  margin-left: 38px;
  padding-bottom: 40px;
}
.left-atomic-text h2 {
  font-family: garet-extrablod;
  font-weight: 800;
  font-size: 120px;
  line-height: 174px;
  color: rgba(133, 94, 66, 0.1);
}
.left-atomic-text h3 {
  font-family: garet-extrablod;
  font-weight: 800;
  font-size: 60px;
  line-height: 87px;
  color: #988171;
  margin-top: -120px;
  margin-left: 5px;
}
.left-atomic-content {
  padding-right: 160px;
}
.left-atomic-content h3 {
  font-family: garet-extrablod;
  font-weight: 850;
  font-size: 36px;
  line-height: 45px;
  color: #121212;
}
.left-atomic-content p {
  font-family: garet-light;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #4d4d4d;
}
.left-atomic-content .get-started {
  font-family: garet-extrablod;
  font-weight: 850;
  font-size: 18px;
  line-height: 26px;
  background: #988171;
  border-radius: 10px 10px 10px 15px;
  color: #ffffff;
  padding: 15px 30px;
  margin-top: 40px;
}
/* Atomic Section End*/

/* Commerce Atomic Start */
.main-commerce-atomic-sec {
  padding: 80px 180px;
  background-color: #988171;
}
.commerce-atomic-head h2 {
  font-family: garet-extrablod;
  font-weight: 850;
  font-size: 60px;
  line-height: 72px;
}
.commerce-atomic-boxes {
  padding: 40px 50px;
}
.commerce-atomic-boxes h3 {
  font-family: garet-extrablod;
  font-weight: 850;
  font-size: 24px;
  line-height: 36px;
}
.commerce-atomic-boxes p {
  font-family: garet-light;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}
/* Commerce Atomic End */

/* Industries Section Start */
.main-industries-sec {
  padding: 80px 180px;
}
.industries-head h2 {
  font-family: garet-extrablod;
  font-weight: 850;
  font-size: 60px;
  line-height: 72px;
}
.industries-boxes img {
  border-radius: 15px;
}
.industries-boxes h3 {
  font-family: garet-extrablod;
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
  color: #121212;
}
.industries-boxes p {
  font-family: garet-light;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 36px;
  color: #4d4d4d;
  padding-inline: 3rem;
}
/* Industries Section End */

/* Innovative Section Start */
.main-innovative-sec {
  padding: 80px 180px;
  background-color: #988171;
}
.innovative-head h2 {
  font-family: garet-extrablod;
  font-weight: 850;
  font-size: 60px;
  line-height: 72px;
}
.innovative-head p {
  font-family: garet-light;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}
.innovative-head .tell-me-more {
  font-family: garet-extrablod;
  font-weight: 850;
  font-size: 18px;
  line-height: 26px;
  color: #988171 !important;
  padding: 15px 30px;
  background-color: #ffffff;
  border-radius: 10px 10px 10px 15px;
}
/* Innovative Section End */

/* Responsive For Mobile */

@media only screen and (max-width: 767px) {
  .main-hero-sec {
    padding-inline: 40px;
    height: 520px;
  }
  .hero-container h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .main-dashboard-sec {
    padding-inline: 40px;
  }
  .dashboard-container p {
    font-size: 30px;
    line-height: 35px;
    padding-top: 80px;
  }
  .main-atomic-sec {
    padding: 40px 40px;
  }
  .atomic-row-content {
    padding: 80px 40px;
    margin-bottom: 0px;
  }
  .atomic-row-right-content {
    flex-direction: column-reverse;
  }
  .left-atomic-text {
    margin-left: unset;
  }
  .left-atomic-text h2 {
    font-size: 40px;
  }
  .left-atomic-text h3 {
    font-size: 30px;
  }
  .left-atomic-content {
    padding-right: unset;
    padding-top: 10px;
  }
  .left-atomic-content h3 {
    font-size: 25px;
  }
  .main-commerce-atomic-sec {
    padding: 80px 40px;
  }
  .commerce-atomic-head h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .main-industries-sec {
    padding: 80px 40px;
  }
  .industries-head h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .industries-boxes h3 {
    font-size: 25px;
  }
  .industries-boxes p {
    padding-inline: unset;
  }
  .main-innovative-sec {
    padding: 80px 40px;
  }
  .innovative-head h2 {
    font-size: 35px;
    line-height: 45px;
  }
}

/* Responsive For Tablet */
@media screen and (max-width: 992px) and (min-width: 668px) {
  .main-hero-sec {
    padding-inline: 40px;
    height: 585px;
  }
  .hero-container h1 {
    font-size: 45px;
  }
  .main-dashboard-sec {
    padding-inline: 45px;
  }
  .dashboard-container p {
    font-size: 35px;
    line-height: 45px;
  }
  .main-atomic-sec {
    padding: 80px 40px;
  }
  .atomic-row-content {
    padding: 80px 40px;
  }
  .left-atomic-text {
    margin-left: unset;
  }
  .left-atomic-text h2 {
    font-size: 45px;
  }
  .left-atomic-text h3 {
    font-size: 30px;
  }
  .left-atomic-content {
    padding-right: unset;
    padding-top: 10px;
  }
  .left-atomic-content h3 {
    font-size: 22px;
    line-height: 25px;
  }
  .left-atomic-content p {
    font-size: 16px;
    line-height: 25px;
  }
  .main-commerce-atomic-sec {
    padding: 80px 40px;
  }
  .commerce-atomic-head h2 {
    font-size: 40px;
  }
  .main-industries-sec {
    padding: 80px 40px;
  }
  .industries-head h2 {
    font-size: 45px;
    line-height: 45px;
  }
  .industries-boxes p {
    padding-inline: unset;
  }
  .main-innovative-sec {
    padding: 80px 40px;
  }
  .innovative-head h2 {
    font-size: 45px;
    line-height: 45px;
  }
}

/* Responsive For laptop */
@media only screen and (min-width: 993px) and (max-width: 1600px) {
  .main-hero-sec {
    padding-inline: 40px;
    height: 745px;
  }
  .hero-container h1 {
    font-size: 45px;
  }
  .main-dashboard-sec {
    padding-inline: 45px;
  }
  .dashboard-container p {
    font-size: 35px;
  }
  .main-atomic-sec {
    padding: 80px 40px;
  }
  .atomic-row-content {
    padding: 80px 40px;
  }
  .left-atomic-text {
    margin-left: unset;
  }
  .left-atomic-text h2 {
    font-size: 50px;
  }
  .left-atomic-text h3 {
    font-size: 50px;
  }
  .left-atomic-content {
    padding-right: unset;
    padding-top: 10px;
  }
  .main-commerce-atomic-sec {
    padding: 80px 40px;
  }
  .commerce-atomic-head h2 {
    font-size: 40px;
  }
  .main-industries-sec {
    padding: 80px 40px;
  }
  .industries-head h2 {
    font-size: 45px;
    line-height: 45px;
  }
  .industries-boxes p {
    padding-inline: unset;
  }
  .main-innovative-sec {
    padding: 80px 40px;
  }
  .innovative-head h2 {
    font-size: 45px;
    line-height: 45px;
  }
}
