.main-footer-sec {
    padding: 80px 274px;
    background: #FFFFFF;
}
.main-footer-sec .logo {
    width: 224px;
}
.main-footer-sec h3 {
    font-family: garet-extrablod;
    font-weight: 800;
    font-size: 18px;
    line-height: 36px;
}
.main-footer-sec ul li a {
    font-family: garet-light;
    font-weight: 800;
    font-size: 18px;
    line-height: 36px;
    text-decoration: none;
    color: #121212;
}

/* Responsive For Mobile */
@media only screen and (max-width: 767px) {
    .main-footer-sec {
        padding: 80px 40px;
    }
}

/* Responsive For Tablet */
@media screen and (max-width: 992px) and (min-width: 668px) {
    .main-footer-sec {
        padding: 80px 40px;
    }
    .footer-logo {
        width: 185px;
    }
}
/* Responsive For laptop */
@media only screen and (min-width: 993px) and (max-width: 1600px) {
    .main-footer-sec {
        padding: 80px 40px;
    }
}